import { Page } from "static/js/app/modules/page";
import "slick-carousel";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import LatestVehicles from "themes/module_latest_vehicles_slider/static/js/latestVehicles";
import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";

export default class HomePage {
  public static init(siteConfig: SiteConfig, pageConfig: PageConfig) {
    Page.ready(function () {
        $('.slick--home-slider').slick({
          slidesToScroll: 1,
          arrows: true,
          prevArrow: "<span class='slick-prev main'>&lt;</span>",
          nextArrow: "<span class='slick-next main'>&gt;</span>",
          autoplay: false,
          centerMode: true,
          variableWidth: true,
          responsive: [
            {
              breakpoint: 800,
              settings: {
                centerMode: false,
                variableWidth: false,
                arrows: false,
                dots: false
              }
            }
          ],
        });
    });
    const financeSearchTerms = VehicleFinanceQuotes.getFinanceSearchTerms(siteConfig.financeConfig);
    // init latest vehicles on home page (not in sidebar like other pages)
    LatestVehicles.init(siteConfig.latestVehiclesConfig.home, siteConfig.latestVehiclesSearchVehicleType, (siteConfig.latestVehiclesShowMonthlyPriceOn.indexOf("home") != -1),financeSearchTerms);

    LatestVehicles.init(siteConfig.latestVehiclesConfig.main, siteConfig.latestVehiclesSearchVehicleType, (siteConfig.latestVehiclesShowMonthlyPriceOn.indexOf("main") != -1),financeSearchTerms);
  }  
}
